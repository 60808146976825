// src/router.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import FormInsert from './components/FormInsert.vue'
import FormBranch from './components/FormInsert.vue'
import AdminReport from './components/ReportData.vue'
import SumReport from './components/ReportCount.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AdminReport',
    component: AdminReport
  },
  {
    path: '/form', //แบบฟอร์ม
    name: 'FormInsert',
    component: FormInsert
  },
  {
    path: '/reportSum',
    name: 'SumReport',
    component: SumReport
  },
  {
    path: '/forminsert/:branch', //แบบฟอร์ม
    name: 'FormBranch',
    component: FormBranch
  },
]

const router = new VueRouter({
  routes
})

export default router
