<template>
    <div>
        <div class="tabnavbar">
            <div class="fullColorTitle">
                <img :src="require('@/assets/images/1694656233994.jpg')" class="aclass">
            </div>
            <b-nav tabs align="center">

                <b-nav-item-dropdown id="my-nav-dropdown" text="แบบฟอร์ม" toggle-class="nav-link-custom" right>
                    <b-dropdown-item><router-link to="/forminsert/bypass">Formบายพาส</router-link></b-dropdown-item>
                    <b-dropdown-item><router-link to="/forminsert/chaofa">Formเจ้าฟ้า</router-link></b-dropdown-item>
                    <b-dropdown-item><router-link to="/forminsert/thaiwatsadu">Formไทวัสดุ</router-link></b-dropdown-item>
                    <b-dropdown-item><router-link to="/forminsert/thalang">Formถลาง</router-link></b-dropdown-item>
                    <b-dropdown-item><router-link to="/forminsert/phangnga">Formพังงา</router-link></b-dropdown-item>
                    <b-dropdown-item><router-link to="/forminsert/khokkloi">Formโคกกลอย</router-link></b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item><router-link to="/">ตารางข้อมูล</router-link></b-nav-item>
                <b-nav-item active><router-link to="/reportSum">ตารางสรุป</router-link></b-nav-item>
                <router-view></router-view>
            </b-nav>
        </div>
        <div class="surveyform">
            <div class="formblock">
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                    <b-container class="formcontainer">
                        <b-row>
                            <b-col sm>
                                <label for="example-datepicker">วันที่เริ่ม</label>
                                <b-form-datepicker id="example-datepicker" v-model="formSearch.startDate"
                                    class="mb-2"></b-form-datepicker>
                            </b-col>
                            <b-col sm>
                                <label for="example-datepicker">วันที่สิ้นสุด</label>
                                <b-form-datepicker id="example-datepicker2" v-model="formSearch.endDate"
                                    class="mb-2"></b-form-datepicker>
                            </b-col>
                        </b-row>
                        <b-row style="text-align: end;">
                            <b-col>
                                <b-button type="submit" variant="outline-secondary" class="searchbtn">ค้นหา</b-button>
                                <b-button type="reset" variant="outline-danger" class="searchbtn">Reset</b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form>
            </div>

            <div class="normalblock">
                <hr />
                <div style="height: 20px ;"></div>
                <div>
                    <b-row>
                        <b-col sm>
                            <p>ข้อมูลสรุปตามสาขาที่สะดวกใช้บริการ</p>
                        </b-col>
                        <b-col sm style="text-align: end;">
                            <b-button size="sm" class="mb-2" style="background-color: #00902B;">
                                <export-excel :data="formData" worksheet="My Worksheet" name="ตารางข้อมูลตามสาขา.xls">
                                    <b-icon icon="download" aria-hidden="true" class="m-1"></b-icon>
                                    Download Excel
                                </export-excel>
                            </b-button>
                        </b-col>
                    </b-row>

                    <div class="tablelock">
                        <b-table  hover responsive="lg" head-variant="dark" :striped=true :items="formData"
                            :fields="fields" ></b-table>

                    </div>
                </div>
                <div style="height: 20px ;"></div>
                <hr />
                <div>
                    <b-row>
                        <b-col sm>
                            <p>ข้อมูลสรุปตามเพศ</p>
                        </b-col>
                        <b-col sm style="text-align: end;">
                            <b-button size="sm" class="mb-2" style="background-color: #00902B;">
                                <export-excel :data="sumPeople" worksheet="My Worksheet" name="ตารางข้อมูลตามเพศ.xls">
                                    <b-icon icon="download" aria-hidden="true" class="m-1"></b-icon>
                                    Download Excel
                                </export-excel>
                            </b-button>
                        </b-col>
                    </b-row>

                    <div class="tablelock">
                        <b-table hover responsive="lg" head-variant="dark" :striped=true  :items="sumPeople"
                            :fields="fieldsPeople"></b-table>

                    </div>
                    <div style="height: 20px ;"></div>
                    <b-row>
                        <b-col sm>
                            <div class="barchart">
                                <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
                                    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
                                    :styles="styles" :width="width" :height="height" />

                            </div>
                        </b-col>

                    </b-row>
                </div>

            </div>
            <div style="height: 20px ;"></div>
        </div>
    </div>
</template>
    
<script>
import axios from 'axios';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    ArcElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs/legacy'


ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend)
const today = new Date(); // วันที่ปัจจุบัน
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
const startday = firstDayOfMonth.toISOString().split('T')[0];
const endDay = new Date().toISOString().split('T')[0];

export default {
    computed: {

    },
    components: {
        Bar
    },
    props: {
        chartId: {
            type: String,
            default: "bar-chart",
        },
        datasetIdKey: {
            type: String,
            default: "label",
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        cssClasses: {
            default: "",
            type: String,
        },
        styles: {
            type: Object,
            default: () => { },
        },
        plugins: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        this.loadData();
        this.loadSumGender();
    },
    data() {

        return {
            formSearch: {
                startDate: startday,
                endDate: endDay,
            },
            fields: ['สาขา', 'ยางรถยนต์', 'ผ้าเบรก', 'สลับยางถ่วงล้อ', 'แม็กซ์', 'โช้ค', 'ตรวจเช็คสภาพรถ', 'น้ำมันเครื่อง', 'ตั้งศูนย์', 'ช่วงล่าง', 'โปรโมชั่นยางรถยนต์', 'โปรโมชั่นน้ำมันเครื่อง', 'โปรโมชั่นช่วงล่าง', 'ชาย', 'หญิง'],
            fieldsPeople: ['เพศ', 'จำนวน', 'ยางรถยนต์', 'ผ้าเบรก', 'สลับยางถ่วงล้อ', 'แม็กซ์', 'โช้ค', 'ตรวจเช็คสภาพรถ', 'น้ำมันเครื่อง', 'ตั้งศูนย์', 'ช่วงล่าง', 'โปรโมชั่นยางรถยนต์', 'โปรโมชั่นน้ำมันเครื่อง', 'โปรโมชั่นช่วงล่าง'],
            formData: [],
            sumPeople: [],
            show: true,
            modalSuccess: false,
            modalWrong: false,
            chartData: {
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.loadData();
            this.loadSumGender();
        },
        onReset(event) {
            event.preventDefault()
            this.formSearch.startDate = startday,
            this.formSearch.endDate = endDay,
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
            this.loadData();
            this.loadSumGender();
        },
        loadData() {
            axios.post("https://survey.eakkarnyang.com/api/sumdata", this.formSearch)
                .then((e) => {
                    //console.log(e.data);
                    this.formData = e.data;

                })
                .catch((error) => {
                    console.log(error);
                    return "error";
                });
        },
        loadSumGender() {
            axios.post("https://survey.eakkarnyang.com/api/sumhgenter", this.formSearch)
                .then((e) => {
                    //console.log(e.data);
                    this.sumPeople = e.data;
                    this.chartData = {
                        labels: [
                            "ยางรถยนต์",
                            "ผ้าเบรก",
                            "สลับยางถ่วงล้อ",
                            "แม็กซ์",
                            "โช้ค",
                            "ตรวจเช็คสภาพรถ",
                            "น้ำมันเครื่อง",
                            "ตั้งศูนย์",
                            "ช่วงล่าง",
                            "โปรโมชั่นยางรถยนต์",
                            "โปรโมชั่นน้ำมันเครื่อง",
                            "โปรโมชั่นช่วงล่าง",
                        ],
                        datasets: [
                            {
                                label: "ชาย",
                                backgroundColor: "#829460",
                                data: [this.sumPeople[0]["ยางรถยนต์"],
                                this.sumPeople[0]["ผ้าเบรก"],
                                this.sumPeople[0]["สลับยางถ่วงล้อ"],
                                this.sumPeople[0]["แม็กซ์"],
                                this.sumPeople[0]["โช้ค"],
                                this.sumPeople[0]["ตรวจเช็คสภาพรถ"],
                                this.sumPeople[0]["น้ำมันเครื่อง"],
                                this.sumPeople[0]["ตั้งศูนย์"],
                                this.sumPeople[0]["ช่วงล่าง"],
                                this.sumPeople[0]["โปรโมชั่นยางรถยนต์"],
                                this.sumPeople[0]["โปรโมชั่นน้ำมันเครื่อง"],
                                this.sumPeople[0]["โปรโมชั่นช่วงล่าง"],
                                ],
                            },
                            {
                                label: "หญิง",
                                backgroundColor: "#f87979",
                                data: [this.sumPeople[1]["ยางรถยนต์"],
                                this.sumPeople[1]["ผ้าเบรก"],
                                this.sumPeople[1]["สลับยางถ่วงล้อ"],
                                this.sumPeople[1]["แม็กซ์"],
                                this.sumPeople[1]["โช้ค"],
                                this.sumPeople[1]["ตรวจเช็คสภาพรถ"],
                                this.sumPeople[1]["น้ำมันเครื่อง"],
                                this.sumPeople[1]["ตั้งศูนย์"],
                                this.sumPeople[1]["ช่วงล่าง"],
                                this.sumPeople[1]["โปรโมชั่นยางรถยนต์"],
                                this.sumPeople[1]["โปรโมชั่นน้ำมันเครื่อง"],
                                this.sumPeople[1]["โปรโมชั่นช่วงล่าง"],
                                ],
                            },
                        ],
                    };

                })
                .catch((error) => {
                    console.log(error);
                    return "error";
                });
        },

    }
}
</script>
    
<style scoped>
a {
    color: #00902B;
}

label {
    color: #00902B;
    font-size: 16px;
    font-weight: bold;
}

p {
    font-size: 20px;
    font-weight: bold;
}
.tabnavbar {
    padding: 30px;
    font-size: 20px;
    background-color: #ffffff;
}

/* หัวข้อ */
.fullColorTitle {
    /* background-color: #00902B; */
    margin-bottom: 30px;
    text-align: center;
}

.surveyform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(to bottom, #ffffff, #ffffff 10%, #ffffff 20%, #CDD0CB 30%, #CDD0CB 40%, #CDD0CB 60%, #CDD0CB 60%, #CDD0CB 80%, #CDD0CB 80%, #CDD0CB 100%);
}

/* รูป */
.aclass {
    width: 300px;
    text-align: center;
}

.tablelock {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
}

.barchart {
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
}

.formblock {
    margin: 5px;
    width: 90vw;
}

.formcontainer {
    border-radius: 20px;
    box-shadow: 5px 5px 10px rgba(114, 114, 114, 0.5);
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #ffffff;
}

.searchbtn {
    font-size: 16px;
    width: 100px;
    margin: 10px;
}

.normalblock {
    text-align: start;
    margin: 5px;
    width: 90vw;
}

@media only screen and (max-width: 1020px) {
    .surveyform {
        background: linear-gradient(to bottom, #ffffff, #ffffff 5%, #ffffff 10%, #ffffff 10%, #CDD0CB 30%, #CDD0CB 60%, #CDD0CB 60%, #CDD0CB 80%, #CDD0CB 80%, #CDD0CB 100%);
    }
}

@media only screen and (max-width: 750px) {
    .aclass {
        width: 200px;
        text-align: center;
    }
    .formblock {
        margin: 15px;
        width: fit-content;
    }
    .barchart {
        padding: 20px;
    }
}
</style>

      