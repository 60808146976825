import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './routes'
import VueExcelXlsx from "vue-excel-xlsx";
import excel from 'vue-excel-export'

Vue.use(excel);

Vue.use(VueExcelXlsx); //ไม่ออก 
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
