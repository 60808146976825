<template>
  <div>
    <div class="tabnavbar">
      <div class="fullColorTitle">
        <img :src="require('@/assets/images/1694656233994.jpg')" class="aclass">
      </div>
      <b-nav tabs align="center">

        <b-nav-item-dropdown id="my-nav-dropdown" text="แบบฟอร์ม" toggle-class="nav-link-custom" right>
          <b-dropdown-item><router-link to="/forminsert/bypass">Formบายพาส</router-link></b-dropdown-item>
          <b-dropdown-item><router-link to="/forminsert/chaofa">Formเจ้าฟ้า</router-link></b-dropdown-item>
          <b-dropdown-item><router-link to="/forminsert/thaiwatsadu">Formไทวัสดุ</router-link></b-dropdown-item>
          <b-dropdown-item><router-link to="/forminsert/thalang">Formถลาง</router-link></b-dropdown-item>
          <b-dropdown-item><router-link to="/forminsert/phangnga">Formพังงา</router-link></b-dropdown-item>
          <b-dropdown-item><router-link to="/forminsert/khokkloi">Formโคกกลอย</router-link></b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item active><router-link to="/">ตารางข้อมูล</router-link></b-nav-item>
        <b-nav-item><router-link to="/reportSum">ตารางสรุป</router-link></b-nav-item>
        <router-view></router-view>
      </b-nav>
    </div>
    <!-- <div  class="blogColor"></div> -->
    <div class="surveyform">
      <div>
        <div class="formblock">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-container class="formcontainer">
              <b-row>
                <b-col sm="8">
                  <b-form-group id="inputgroup1" label-for="input1"><label>สาขา:</label>
                    <b-form-select id="input1" v-model="formSelect.branch" :options="optionsPlace"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="4"></b-col>
              </b-row>

              <b-row>
                <b-col sm>
                  <b-form-group id="inputgroup2" label-for="input2"><label>สินค้าหรือบริการ:</label>
                    <b-form-select id="input2" v-model="formSelect.shop" :options="optionsShop"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm>
                  <b-form-group id="inputgroup3" label-for="input3"><label>โปรโมชั่น:</label>
                    <b-form-select id="input3" v-model="formSelect.promotion" :options="optionsPromotion"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm>
                  <b-form-group id="inputgroup4" label-for="input4"><label>เพศ:</label>
                    <b-form-select id="input4" v-model="formSelect.gender" :options="optionsGender"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <label for="example-datepicker">วันที่เริ่ม</label>
                  <b-form-datepicker id="example-datepicker" v-model="formSelect.startDate"
                    class="mb-2"></b-form-datepicker>
                </b-col>
                <b-col cols="6">
                  <label for="example-datepicker">วันที่สิ้นสุด</label>
                  <b-form-datepicker id="example-datepicker2" v-model="formSelect.endDate"
                    class="mb-2"></b-form-datepicker>
                </b-col>
              </b-row>
              <b-row>
                <b-col style="text-align: end;">
                  <b-button type="submit" variant="outline-secondary" class="searchbtn">ค้นหา</b-button>
                  <b-button type="reset" variant="outline-danger" class="searchbtn">Reset</b-button>
                </b-col>
              </b-row>
            </b-container>


          </b-form>
          <div style=" height: 50px;"></div>
          <div class="h1 " style="text-align: center;">
            <p>แสดงผลสำรวจของสาขา</p>
            <b-icon icon="chevron-compact-down" style="color: #7952b3;" animation="throb"
              @click="showBranch = !showBranch"></b-icon>
          </div>
          <!-- {{ formSelect }} -->
        </div>
      </div>
      <div style="height: 2px ;"></div>
      <div class="normalblock">
        <b-container v-show="showBranch" class="formcontainer">
          <hr />
          <p style="text-align: center; font-size: 23px;"><b> ผลสำรวจของสาขา</b></p>
          <b-row>
            <b-col sm>
              <div class="sumblock">
                <div class="p2">ไทร์พลัส เอกการยาง สาขาบายพาสภูเก็ต :
                  {{ branchdata[0]['สาขาบายพาสภูเก็ต'] }} คน</div>
                <div class="p2">ไทร์พลัส เอกการยาง สาขาเจ้าฟ้าตะวันออก :
                  {{ branchdata[0]['สาขาเจ้าฟ้าตะวันออก'] }} คน</div>
                <div class="p2">ไทร์พลัส เอกการยาง สาขาโคกกลอย :
                  {{ branchdata[0]['สาขาโคกกลอย'] }} คน</div>
                <div class="p2">ไทร์พลัส เอกการยางเอกการยาง สาขาเมืองพังงา :
                  {{ branchdata[0]['สาขาเมืองพังงา'] }} คน</div>
                <div class="p2">ไทร์พลัส เอกการยาง สาขาไทวัสดุ (ท่าเรือ) :
                  {{ branchdata[0]['สาขาไทวัสดุท่าเรือ'] }} คน</div>
                <div class="p2">ไทร์พลัส เอกการยางสาขาถลาง (ทางเข้าสนามบิน) :
                  {{ branchdata[0]['สาขาถลางทางเข้าสนามบิน'] }} คน</div>

              </div>
            </b-col>
            <b-col sm>
              <Pie :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
                :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles"
                :width="width" :height="height" />
            </b-col>

          </b-row>
          <hr />
        </b-container>
      </div>
      <div style="height: 2px ;"></div>
      <div class="normalblock">
        <div style="padding: 20px;"></div>
        <b-container style="padding-bottom: 30px;">
          <b-row>
            <b-col sm="6">
              <b-form-group label-for="filter-input"><label>ค้นหา</label>
                <b-input-group>
                  <b-form-input id="filter-input" v-model="filter" type="search" placeholder=""></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">ล้าง</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label-for="per-page-select"><label>หน้า</label>
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
              </b-form-group>
            </b-col></b-row>


        </b-container>
        <div style="padding: 0px;">
          <b-row class="exportbtn" v-if="formData.length > 0">
            <b-col sm>
              <b-pagination class="pagebtn" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                align="fill"></b-pagination>
            </b-col>
            
            <b-col sm>
              <b-button size="sm" class="mb-2" style="background-color: #00902B;">
                <export-excel :data="formData" worksheet="My Worksheet" name="ตารางข้อมูล.xls">
                  <b-icon icon="download" aria-hidden="true" class="m-1"></b-icon>
                  Download Excel
                </export-excel></b-button>
            </b-col>
          </b-row>
          <div class="tablelock">
            <b-table hover responsive="lg" :striped=true :bordered=true :items="formData" :fields="fieldsTH"
              :current-page="currentPage" :per-page="perPage" :filter="filter" @filtered="onFiltered" head-variant="dark">
              <template v-slot:cell(ลำดับ)="data">
                <div style="text-align: center;">{{ data.index + 1 }}</div>
              </template>
             

        </b-table>
      </div>
    </div>

  </div>
  <div style="height: 20px ;"></div>
</div>
</div></template>
  
<script>
import axios from 'axios';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Pie } from 'vue-chartjs/legacy'
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend)
const today = new Date(); // วันที่ปัจจุบัน
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
const startday = firstDayOfMonth.toISOString().split('T')[0];
const endDay = new Date().toISOString().split('T')[0];

export default {
  components: {
    Pie
  },
  computed: {

  },
  mounted() {
    this.serachData();
    this.loadBranchQua();
  },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 500
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {

    return {
      form: {
        firstName: '',
        tel: '',
        gender: '',
        selectedShop: [],
        selectedPromotion: [],
        selectedPlace: 'ไทร์พลัส เอกการยาง สาขาโคกกลอย',
        text: '',
        status: 'accepted',
      },
      formSelect: {
        branch: '',
        shop: '',
        promotion: '',
        gender: '',
        startDate: startday,
        endDate: endDay,
      },
      optionsPlace: [{ text: 'ทั้งหมด', value: '' }, 'ไทร์พลัส เอกการยาง สาขาโคกกลอย',
        'ไทร์พลัส เอกการยาง สาขาบายพาสภูเก็ต',
        'ไทร์พลัส เอกการยางสาขาถลาง (ทางเข้าสนามบิน)',
        'ไทร์พลัส เอกการยาง สาขาเจ้าฟ้าตะวันออก',
        'ไทร์พลัส เอกการยาง สาขาไทวัสดุ (ท่าเรือ)',
        'ไทร์พลัส เอกการยางเอกการยาง สาขาเมืองพังงา'
      ],
      optionsShop: [{ text: 'ทั้งหมด', value: '' }, 'ยางรถยนต์', 'ผ้าเบรก', 'สลับยาง-ถ่วงล้อ', 'แม็กซ์', 'โช้ค', 'ตรวจเช็คสภาพรถ', 'น้ำมันเครื่อง', 'ตั้งศูนย์', 'ช่วงล่าง'],
      optionsPromotion: [{ text: 'ทั้งหมด', value: '' }, 'โปรโมชั่นยางรถยนต์', 'โปรโมชั่นน้ำมันเครื่อง', 'โปรโมชั่นช่วงล่าง'],
      optionsGender: [{ text: 'ทั้งหมด', value: '' }, 'ชาย', 'หญิง'],
      fields: ['ลำดับ', 'firstname', 'tel', 'gender', 'selectedshop', 'selectedpromotion', 'selectedplace', 'text', 'status', 'createdata'],
      fieldsTH: ['ลำดับ', 'ชื่อ', 'เบอร์โทรศัพท์', 'เพศ', 'สินค้าหรือบริการ', 'โปรโมชั่น', 'สาขาที่สะดวกใช้บริการ', 'ข้อเสนอเพิ่มเติม', 'วันที่บันทึก'],
      formData: [],
      show: true,
      showBranch: false,
      branchdata: [{
        "สาขาบายพาสภูเก็ต": 0,
        "สาขาเจ้าฟ้าตะวันออก": 0,
        "สาขาโคกกลอย": 0,
        "สาขาเมืองพังงา": 0,
        "สาขาไทวัสดุท่าเรือ": 0,
        "สาขาถลางทางเข้าสนามบิน": 0
      }],
      modalSuccess: false,
      modalWrong: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [5, 10, 15, { value: 100, text: "ทั้งหมด" }],
      filter: null,
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.serachData();
      this.loadBranchQua();
    },
    onReset(event) {
      event.preventDefault()
      this.formSelect.branch = ''
      this.formSelect.shop = ''
      this.formSelect.promotion = ''
      this.formSelect.gender = ''
      this.formSelect.startDate = startday,
      this.formSelect.endDate = endDay,
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
      this.serachData();
    },
    loadBranchQua() {
      axios.post("https://survey.eakkarnyang.com/api/branch", this.formSelect)
        .then((e) => {
          //console.log(e.data);
          this.branchdata = e.data;
          this.chartData = {
            labels: ['สาขาบายพาสภูเก็ต', 'สาขาเจ้าฟ้าตะวันออก', 'สาขาโคกกลอย', 'สาขาเมืองพังงา', 'สาขาไทวัสดุ(ท่าเรือ)', 'สาขาถลาง(ทางเข้าสนามบิน)'],
            datasets: [
              {
                backgroundColor: ['#D1D59F', '#B3C79D', '#62A7AF', '#5C5B81', '#745669', '#B694B4'],
                data: [this.branchdata[0]['สาขาบายพาสภูเก็ต'], this.branchdata[0]['สาขาเจ้าฟ้าตะวันออก'], this.branchdata[0]['สาขาโคกกลอย'], this.branchdata[0]['สาขาเมืองพังงา'], this.branchdata[0]['สาขาไทวัสดุท่าเรือ'], this.branchdata[0]['สาขาถลางทางเข้าสนามบิน']]
              }
            ]
          };

        })
        .catch((error) => {
          console.log(error);
          return "error";
        });
    },
    serachData() {
      console.log("ก่อนส่งข้อมุล ", this.formSelect)
      axios.post("https://survey.eakkarnyang.com/api/search", this.formSelect)
        .then((e) => {
          //console.log(e.data);
          this.formData = e.data;
          this.totalRows = this.formData.length

        })
        .catch((error) => {
          console.log(error);
          return "error";
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>
  
<style scoped>
p {
  font-weight: bold;
  font-size: 18px;
  color: #00902B;
}

.p2 {
  font-size: 18px;
  color: #00902B;
  margin: 20px;
}

.tabnavbar {
  padding: 30px;
  font-size: 20px;
  background-color: #ffffff;
}

.formblock {
  text-align: start;
  margin: 5px;
  width: 90vw;
}

.formcontainer {
  border-radius: 20px;
  margin-top: 50px;
  background-color: #ffffff;
  box-shadow: 5px 5px 10px rgba(114, 114, 114, 0.5);
  padding: 30px;
}

.normalblock {
  text-align: start;
  margin: 5px;
  width: 90vw;

}

.surveyform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #ffffff, #ffffff 10%, #ffffff 20%, #CDD0CB 30%, #CDD0CB 40%, #CDD0CB 60%, #CDD0CB 60%, #CDD0CB 80%, #CDD0CB 80%, #CDD0CB 100%);
}

.sumblock {
  text-align: start;
  margin-right: 5px;
}

.tablelock {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}

label {
  color: #00902B;
  font-weight: bold;
}

a {
  color: #00902B;
}

.searchbtn {
  font-size: 16px;
  width: 100px;
  margin: 10px;
}

.exportbtn {
  text-align: end;
}

.pagebtn {
  width: 10px;
}

.blogColor {
  width: 100%;
  height: 50px;
  position: absolute;
  top: 75px;
  background-color: #FFE1E1;
}

.fullColorTitle {
  /* background-color: #00902B; */
  margin-bottom: 30px;
  text-align: center;
}

/* รูป */
.aclass {
  width: 300px;
  text-align: center;
}

@media only screen and (max-width: 1020px) {
  .surveyform {
    background: linear-gradient(to bottom, #ffffff, #ffffff 5%, #ffffff 10%, #ffffff 10%, #CDD0CB 30%, #CDD0CB 60%, #CDD0CB 60%, #CDD0CB 80%, #CDD0CB 80%, #CDD0CB 100%);
  }
}

@media only screen and (max-width: 750px) {
  .aclass {
    width: 200px;
    text-align: center;
  }

  .formblock {
    text-align: start;
    margin: 15px;
    background-color: rgb(252, 252, 252);
    width: fit-content;
  }

  .blogColor {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 120px;
    background-color: #FFE1E1;
  }
}
</style>
    