<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
   
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai&display=swap');

#app {
  font-family: 'Noto Sans Thai',Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 00px;
}
html, body {
  font-family: 'Noto Sans Thai', sans-serif;
}
#my-nav-dropdown .nav-link-custom {
  color: #00902B; /* สีเขียวตามที่คุณต้องการ */
}

.page-item.active .page-link{
  background-color: #B7B7B7;
  border: #B7B7B7 ;
  
}
.page-link{
  color: #2c3e50;
}
.custom-select{
  color: #00902B
}
.table .thead-dark th {
    color: #fff;
    background-color: #00902B;
    border-color: #454d55;
    text-align: center;
}
.table-hover tbody tr:hover {
  background-color: #F6E6E4; /* เปลี่ยนสีพื้นหลังเมื่อ hover ตามที่คุณต้องการ */
  /* คุณสามารถกำหนดคุณสมบัติอื่น ๆ ตามต้องการ */
}
.b-table th:first-child, .b-table td:first-child {
  text-align: left;
}

/* ให้คอลัมน์ที่เหลือชิดกลาง */
.b-table th:not(:first-child), .b-table td:not(:first-child) {
  text-align: center;
} 

</style>
