<template>
  <div class="surveyform">
    <div class="">
      <div class="fullColorTitle">
        <img :src="require('@/assets/images/1694656233994.jpg')" class="aclass">
      </div>

      <br />
      <p style="padding: 0 10px 0 10px;">แบบสำรวจการใช้บริการ ศูนย์บริการ ไทร์พลัส เอกการยาง</p>
    </div>


    <div class="bodyclass">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">

        <b-container fluid="sm">
          <b-row>
            <b-col sm>
              <b-form-group id="inputgroup1" label-for="input1">
                <label>ชื่อ</label>
                <b-form-input id="input1" v-model="form.firstName" :state="nameState"
                  required></b-form-input>
                <!-- This will only be shown if the preceding input has an invalid state -->
                

                <!-- This is a form text block (formerly known as help block) -->
                <b-form-text id="input-live-help"></b-form-text>
              </b-form-group>
            </b-col>
            <b-col sm>
              <b-form-group id="telFormGroup" label-for="input2">
                <label>เบอร์โทรศัพท์</label>
                <b-form-input id="input2" v-model="form.tel" required type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  :formatter="formatter" :state="telState"
                  aria-describedby="input-live-help input-live-feedback"></b-form-input>
                <!-- This will only be shown if the preceding input has an invalid state -->
                <b-form-invalid-feedback id="input-live-feedback">
                  กรอกรูปแบบเบอร์โทรศัพท์ให้ถูกต้อง
                </b-form-invalid-feedback>

                <!-- This is a form text block (formerly known as help block) -->
                <b-form-text id="input-live-help"></b-form-text>
              </b-form-group></b-col>

          </b-row>

          <b-row>
            <b-col cols="8">
              <b-form-group id="inputgroup3" v-slot="{ ariaDescribedby }">
                <label>เพศ</label>
                <b-form-radio-group v-model="form.gender" class="pt-2" :options="['ชาย', 'หญิง']"
                  :aria-describedby="ariaDescribedby" required></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="4"></b-col>
          </b-row>

          <b-row>
            <b-col col>
              <b-form-group ref="inputgroup4" id="inputgroup4" v-slot="{ ariaDescribedby }">
                <label>สินค้า หรือบริการที่ท่านสนใจ</label>
                <b-form-checkbox-group id="checkboxgroup1" v-model="form.selectedShop"
                  :aria-describedby="ariaDescribedby" name="flavour-1">
                  <b-row>
                    <b-col sm><b-form-checkbox  ref="checkInput" value="ยางรถยนต์">ยางรถยนต์</b-form-checkbox></b-col>
                    <b-col sm><b-form-checkbox value="ผ้าเบรก">ผ้าเบรก</b-form-checkbox></b-col>
                    <b-col sm><b-form-checkbox value="สลับยาง-ถ่วงล้อ">สลับยาง-ถ่วงล้อ</b-form-checkbox></b-col>
                  </b-row>
                  <b-row>
                    <b-col sm><b-form-checkbox value="แม็กซ์">แม็กซ์</b-form-checkbox></b-col>
                    <b-col sm><b-form-checkbox value="โช้ค">โช้ค</b-form-checkbox></b-col>
                    <b-col sm><b-form-checkbox value="ตรวจเช็คสภาพรถ">ตรวจเช็คสภาพรถ</b-form-checkbox></b-col>
                  </b-row>
                  <b-row>
                    <b-col sm><b-form-checkbox value="น้ำมันเครื่อง">น้ำมันเครื่อง</b-form-checkbox></b-col>
                    <b-col sm><b-form-checkbox value="ตั้งศูนย์">ตั้งศูนย์</b-form-checkbox></b-col>
                    <b-col sm><b-form-checkbox value="ช่วงล่าง">ช่วงล่าง</b-form-checkbox></b-col>
                  </b-row>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="8">
              <b-form-group id="input-group-5" v-slot="{ ariaDescribedby }">
                <label>โปรโมชั่นที่ท่านสนใจ</label>
                <b-form-checkbox-group id="checkboxgroup2" v-model="form.selectedPromotion"
                  :aria-describedby="ariaDescribedby" name="flavour2">
                  <b-row>
                    <b-col sm><b-form-checkbox ref="checkInput2" value="โปรโมชั่นยางรถยนต์">โปรโมชั่นยางรถยนต์</b-form-checkbox></b-col>
                    <b-col sm><b-form-checkbox value="โปรโมชั่นน้ำมันเครื่อง">โปรโมชั่นน้ำมันเครื่อง</b-form-checkbox></b-col>
                    <b-col sm><b-form-checkbox value="โปรโมชั่นช่วงล่าง">โปรโมชั่นช่วงล่าง</b-form-checkbox></b-col>
                  </b-row>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
            <b-col cols="4"></b-col>
          </b-row>

          <b-row>
            <b-col sm="8">
              <b-form-group id="inputgroup6" label-for="input-6">
                <label>สะดวกใช้บริการที่ ไทร์พลัส เอกการยาง สาขาใด</label>
                <b-form-select id="input6" v-model="form.selectedPlace" :options="optionsPlace" required
                  class="textGreen"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4"></b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group id="inputgroup7">
                <label>ข้อเสนอเพิ่มเติม </label> <label style="color: #f30606;">(ถ้ามี) </label>
                <b-form-textarea id="textarea" v-model="form.text"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-checkbox id="checkbox-accepted" v-model="form.status" name="checkStatus" value="accepted"
                unchecked-value="not_accepted" required class="checkboxAccept" size="sm">
                ท่านยินยอมให้ไทร์พลัส เอกการยาง ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เพื่อประชาสัมพันธ์ หรือแนะนำเกี่ยวกับผลิตภัณฑ์/บริการ และสิทธิประโยชน์ทางการตลาดกับท่าน
              </b-form-checkbox>
            </b-col>
          </b-row>

          <div class=" text-center mt-3">
            <b-button type="submit" class="sentbtn">ส่งแบบสำรวจ</b-button>
            <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
          </div>



        </b-container>
      </b-form>

    </div>

    <b-modal id="modal"  v-model="modalSuccess" centered ok-only   >
      <p class="my-4 " style="text-align: center; color:  #00902B;">ขอขอบคุณสำหรับการเข้าร่วมตอบแบบสำรวจ ทุกความเห็นของท่านมีความสำคัญสำหรับไทร์พลัส เอกการยางอย่างมาก</p>
      <template #modal-footer="{ ok }">
      <b-button size="sm" variant="success" @click="ok()">
        OK
      </b-button>
    </template>
    </b-modal>

    <b-modal id="modal"  v-model="modalWrong" centered ok-only   >
      <p class="my-4 " style="text-align: center; color: red;">เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง</p>
      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="danger" @click="ok()">
          OK
      </b-button>
    </template>
    </b-modal>
    <!-- {{ form }}
 -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  created(){
      this.form.branchscan = this.$route.params.branch;
    },
  computed: {
    telState() {
      // return this.form.tel.length == 12 ? true : false
      if (/^[0-9-]+$/.test(this.form.tel)) {
        if (this.form.tel.length == 12) {
          return true;
        }
        else {
          return false;
        }
      } else {
        return false;
      }
    },
    nameState() {
        return this.form.firstName.length != 0 ? true : false
      }
  },
  mounted(){
    //this.loadData();
    //console.log(this.form.branchscan);
  },
  data() {
    return {
      form: {
        firstName: '',
        tel: '',
        gender: '',
        selectedShop: [],
        selectedPromotion: [],
        selectedPlace: 'ไทร์พลัส เอกการยาง สาขาโคกกลอย',
        text: '',
        status: 'accepted',
        branchscan: '',
      },
      optionsPlace: ['ไทร์พลัส เอกการยาง สาขาโคกกลอย',
        'ไทร์พลัส เอกการยาง สาขาบายพาสภูเก็ต',
        'ไทร์พลัส เอกการยางสาขาถลาง (ทางเข้าสนามบิน)',
        'ไทร์พลัส เอกการยาง สาขาเจ้าฟ้าตะวันออก',
        'ไทร์พลัส เอกการยาง สาขาไทวัสดุ (ท่าเรือ)',
        'ไทร์พลัส เอกการยางเอกการยาง สาขาเมืองพังงา'
      ],
      show: true,
      modalSuccess : false,
      modalWrong : false,
    }
  },
  methods: {
    onSubmit(event) {

      event.preventDefault()
      // alert(JSON.stringify(this.form))
      if(this.form.selectedShop.length == 0){
        //this.toast('b-toaster-top-right')
        this.$refs.checkInput.focus();
      }
      else if(this.form.selectedPromotion.length == 0) {
        //this.toast('b-toaster-top-right')
        this.$refs.checkInput2.focus();
      }
      else
      {
        this.insertData();
      }
      
    
    },
    onReset() {
      // Reset our form values
      this.form.firstName = ''
      this.form.tel = ''
      this.form.gender = ''
      this.form.selectedShop = []
      this.form.selectedPromotion = []
      this.form.selectedPlace = 'ไทร์พลัส เอกการยาง สาขาโคกกลอย'
      this.form.text = ''
      this.form.status = 'accepted',
        // Trick to reset/clear native browser form validation state
        this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    formatter(value) {
      return value.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
    },
    loadData(){
      axios.get("https://survey.eakkarnyang.com/api/data")
      .then((e) =>{
        console.log(e.data);
      })
      .catch((error) => {
        console.log(error);

      });
    },
    insertData(){
      axios.post("https://survey.eakkarnyang.com/api/insertdata",this.form)
      .then((e) =>{
        console.log(e.data);
        if(e.data == "ok")
        {
          this.modalSuccess = true;
          this.onReset();
        }
        else{
          this.modalWrong = true;
        }
      })
      .catch((error) => {
        console.error('เกิดข้อผิดพลาดในการส่งข้อมูล:', error);
        this.modalWrong = true;
      }); 
    },
    toast(toaster, append = false) {
        this.counter++
        this.$bvToast.toast(`กรุณากรอกข้อมูลให้ครบถ้วน`, {
          title: `ข้อมูลไม่ถูกต้อง`,
          toaster: toaster,
          solid: true,
          appendToast: append
        })
      }
  }
}
</script>

<style scoped>
label {
  color: #00902B;
  font-size: 16px;
  font-weight: bold;
}

p {
  font-size: 20px;
  font-weight: bold;
}

.checkboxAccept {
  font-size: 14px;

}

/* หัวข้อ */
.fullColorTitle {
  /* background-color: #00902B; */
  margin-top: 20px;
}

.surveyform {
  padding: 0px 20px 20px 20px;
  text-align: center;

}

/* รูป */
.aclass {
  width: 300px;
  text-align: center;
}

.bodyclass {
  padding-top: 20px;
  text-align: start;
  /* color: #00902B; */
}

.textGreen {
  color: #00902B;
}

.sentbtn {
  font-size: 16px;
  width: 250px;
  background-color: #00902B;
}

@media only screen and (max-width: 750px) {
  .aclass {
    width: 200px;
    text-align: center;
  }
}
</style>
  